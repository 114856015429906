import api from "@/api/index";
import store from "@/store";
import { setEmoji } from "@/handlers/emoji";

export function getContacts(that) {
  api
    .get("contact/list", {
      params: {
        company: store.state.company.curCompany
          ? store.state.company.curCompany.id
          : "",
      },
    })
    .then((r) => {
      console.info(r.statusText + " " + r.status + " " + setEmoji("access"));
      that.contactList = r.data;
    })
    .catch((err) => {
      console.log(err);
      let statusCode = err.request.status;
      let statusText = err.request.statusText;
      console.error(setEmoji("error") + statusCode + " " + statusText);
      switch (statusCode) {
        case 0:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("error")} Нет связи с сервером`,
            detail: "Попробуйте позднее!",
          });
          break;
        case 403:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("lock")} Время сессии истекло`,
            detail: "Требуется авторизация",
            life: 5000,
          });
          that.$router.push("/login");
          break;
        case 404:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Ошибка ${statusCode}`,
            detail: "Запрос не найден",
            life: 5000,
          });
          break;
        default:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Неизвестная ошибка ${statusCode}`,
            detail: "Попробуйте позднее!",
            life: 5000,
          });
          break;
      }
    });
}

export function postContacts(that) {
  let action = that.dataUser.action;
  //   let indexHolder =
  //     that.holders.length > 0
  //       ? that.holders.indexOf(that.selectedHolder[0])
  //       : null;
  let newContact = {
    action: that.dataUser.action,
    company: store.state.company.curCompany
      ? store.state.company.curCompany.id
      : "",
    phone: that.dataUser.phone,
    email: that.dataUser.email,
    fio: that.dataUser.fio,
    position: that.dataUser.position,
    pk: that.dataUser.pk,
  };
  api
    .post("company/contact", {
      action: that.dataUser.action,
      company: store.state.company.curCompany
        ? store.state.company.curCompany.id
        : "",
      phone: that.dataUser.phone,
      email: that.dataUser.email,
      fio: that.dataUser.fio,
      position: that.dataUser.position,
      pk: that.dataUser.pk,
    })
    .then((r) => {
      console.info(r.statusText + " " + r.status + " " + setEmoji("access"));

      if (that.dataUser.action === "add") {
        console.log(r.data.pk);
        newContact.pk = r.data.pk;
        console.log(newContact);
        store.state.company.curCompany.contact.push(newContact);
        that.$toast.add({
          severity: "success",
          summary: `${newContact.fio} `,
          detail: `${setEmoji("access")} Успешно добавлен`,
          life: 3000,
        });
      } else if (action === "del") {
        store.state.company.curCompany.contact.splice(that.indexContact, 1);
        that.$toast.add({
          severity: "success",
          summary: `Контакт`,
          detail: `${setEmoji("trash")} Успешно удален`,
          life: 3000,
        });
      } else if (action === "edit") {
        store.state.company.curCompany.contact[that.indexContact] = newContact;
        that.loading = false;
        that.$toast.add({
          severity: "success",
          summary: `Данные контакта ${newContact.fio}`,
          detail: `${setEmoji("access")} Успешно отредактированы`,
          life: 3000,
        });
      }
    })
    .catch((err) => {
      console.log(err);
      let statusCode = err.request.status;
      let statusText = err.request.statusText;
      console.error(setEmoji("error") + statusCode + " " + statusText);
      switch (statusCode) {
        case 0:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("error")} Нет связи с сервером`,
            detail: "Попробуйте позднее!",
          });
          break;
        case 403:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("lock")} Время сессии истекло`,
            detail: "Требуется авторизация",
            life: 5000,
          });
          that.$router.push("/login");
          break;
        case 404:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Ошибка ${statusCode}`,
            detail: "Запрос не найден",
            life: 5000,
          });
          break;
        default:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Неизвестная ошибка ${statusCode}`,
            detail: "Попробуйте позднее!",
            life: 5000,
          });
          break;
      }
    });
}
