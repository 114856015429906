// Нужно прокинуть значение в параметр timeValue,
// все приведется в формат ISO(день-месяц-год), если часы не нужны, то hour = false(по умолчанию true)

export function timeFormater(timeValue, hour = true) { 
  if (timeValue) {
    let date = timeValue.split("T")[0];
    if (hour) {
      let hours = timeValue.split("T")[1].split(".")[0];
      let year = date.split("-")[0];
      let mounth = date.split("-")[1];
      let days = date.split("-")[2];
      return days + "." + mounth + "." + year + " " + hours;
    } else {
      let year = date.split("-")[0];
      let mounth = date.split("-")[1];
      let days = date.split("-")[2];
      return days + "." + mounth + "." + year;
    }
  }
}
