<template>
  <layout-base>
    <LoadingProgress :loading="loading" />
    <MobileSpiner :loading="loading" /> 
    <div
      v-if="
        $store.state.company.companyList &&
        $store.state.company.companyList.length &&
        $store.state.company.curCompany != null
      "
      class="mt-1"
    >
      <div
        class="min-h-screen flex relative lg:static shadow-6"
        :class="{
          'bg-green-50': $store.state.company.curCompany.status === 1,
          'bg-red-50': $store.state.company.curCompany.status === 2,
        }"
      >
        <div class="min-h-screen flex flex-column relative flex-auto">
          <div class="flex flex-column flex-auto">
            <div class="surface-section p-3 sm:p-5">
              <div
                class="flex align-items-end flex-column-reverse sm:align-items-start sm:flex-row sm:justify-content-between"
              >
                <div class="flex align-items-start flex-column md:flex-row">
                  <div>
                    <span class="text-900 font-medium text-3xl"
                      >{{
                        $store.state.company.curCompany
                          ? $store.state.company.curCompany.name
                          : ""
                      }}
                      <i
                        class="pi pi-briefcase text-2xl ml-4 text-yellow-500"
                      ></i>
                    </span>

                    <div class="flex align-items-stretch flex-wrap text-sm">
                      <div class="mr-5 mt-3">
                        <span class="font-medium text-500">Автоблок</span>
                        <div class="text-700 mt-2">
                          <b>
                            {{
                              $store.state.company.curCompany.autoblock
                                ? "Да"
                                : "Нет"
                            }}</b
                          >
                        </div>
                      </div>
                      <div class="mr-5 mt-3">
                        <span
                          class="font-medium text-500 flex align-items-center cursor-pointer"
                          >Сигнальный порог
                          <i
                            v-tooltip="
                              $store.state.company.curCompany.the_reason
                                ? $store.state.company.curCompany.the_reason
                                : 'Без оснований'
                            "
                            class="pi pi-info-circle ml-1"
                          ></i>
                        </span>
                        <div class="text-700 mt-2">
                          <b>
                            {{
                              $store.state.company.curCompany.limit
                                ? $store.state.company.curCompany.limit + " Р"
                                : "-"
                            }}</b
                          >
                        </div>
                      </div>
                      <div class="mr-5 mt-3">
                        <span class="font-medium text-500">Номер договора</span>
                        <div class="text-700 mt-2">
                          <b>
                            {{
                              $store.state.company.curCompany.contract_num
                                ? $store.state.company.curCompany.contract_num
                                : "-"
                            }}</b
                          >
                        </div>
                      </div>
                      <div class="mr-5 mt-3">
                        <span class="font-medium text-500">Договор от</span>
                        <div class="text-700 mt-2">
                          <b>
                            {{
                              $store.state.company.curCompany.contract_date
                                ? formaterDate(
                                    $store.state.company.curCompany
                                      .contract_date,
                                    false
                                  ) // $store.state.curCompany.contract_date //     .split("T")[0] //     .split("-")[2] + //   "." + //   $store.state.curCompany.contract_date //     .split("T")[0] //     .split("-")[1] + //   "." + //   $store.state.curCompany.contract_date //     .split("T")[0] //     .split("-")[0]
                                : "-"
                            }}</b
                          >
                        </div>
                      </div>
                      <div class="mt-3">
                        <span class="font-medium text-500">ИНН</span>
                        <div class="text-700 mt-2">
                          <b>
                            {{
                              $store.state.company.curCompany.inn
                                ? $store.state.company.curCompany.inn
                                : "-"
                            }}</b
                          >
                        </div>
                      </div>

                      <!-- <TheCalendarFromTo v-on:setDate="logger" /> -->
                      <!-- <div class="mt-3">
                        <span class="font-medium text-500">Адрес</span>
                        <div class="text-700 mt-2">
                          <b>
                            {{
                              $store.state.curCompany.adress
                                ? $store.state.curCompany.adress
                                : "-"
                            }}</b
                          >
                        </div>
                      </div> -->
                    </div>
                  </div>
                </div>
                <!-- <div class="sm:mb-0 mb-4 sm:w-auto w-full">
                  <Tag
                    class="text-base px-3 sm:w-auto w-full"
                    :severity="
                      config.STATUS[$store.state.company.curCompany.status].tag
                    "
                    :rounded="false"
                    :value="
                      config.STATUS[$store.state.company.curCompany.status].name
                    "
                  ></Tag>
                </div> -->
              </div>
            </div>
            <div class="pt-5 p-3">
              <div class="grid align-items-stretch">
                <div class="col-12 lg:col-6 xl:col-3">
                  <balances-card
                    :statusName="
                      config.STATUS[$store.state.company.curCompany.status].name
                    "
                    :statusClass="
                      config.STATUS[$store.state.company.curCompany.status]
                        .class
                    "
                  />
                </div>
                <div class="col-12 lg:col-6 xl:col-3">
                  <div
                    class="surface-card shadow-2 p-3 h-full flex flex-column justify-content-between border-1 border-50 border-round"
                  >
                    <div class="flex justify-content-between mb-3">
                      <div>
                        <span class="block text-500 font-medium mb-3"
                          >Последний платеж</span
                        >
                        <div class="text-900 font-medium text-xl">
                          {{
                            $store.state.company.curCompany.last_payment?.sum
                              ? $store.state.company.curCompany.last_payment
                                  ?.sum + " Р"
                              : "-"
                          }}
                          <sup class="text-gray-500 text-sm">{{
                            $store.state.company.curCompany.last_payment
                              ?.description
                              ? $store.state.company.curCompany.last_payment
                                  ?.description
                              : ""
                          }}</sup>
                        </div>
                      </div>
                      <div
                        class="flex align-items-center justify-content-center bg-orange-100 border-round"
                        style="width: 2.5rem; height: 2.5rem"
                      >
                        <i class="pi pi-wallet text-orange-500 text-xl"></i>
                      </div>
                    </div>
                    <span class="text-500 font-medium">
                      {{
                        $store.state.company.curCompany.last_payment?.date
                          ? formaterDate(
                              $store.state.company.curCompany.last_payment?.date
                            )
                          : "-"
                      }}</span
                    >
                  </div>
                </div>

                <div class="col-12 lg:col-6 xl:col-3">
                  <div
                    class="surface-card shadow-2 p-3 h-full flex flex-column justify-content-between border-1 border-50 border-round"
                  >
                    <div class="flex justify-content-between mb-3">
                      <div>
                        <span class="block text-500 font-medium mb-3"
                          >Карты</span
                        >
                        <div class="text-900 font-medium text-xl">
                          {{ $store.state.company.curCompany.card_count }}
                        </div>
                      </div>
                      <div
                        class="flex align-items-center justify-content-center bg-blue-100 border-round"
                        style="width: 2.5rem; height: 2.5rem"
                      >
                        <i class="pi pi-credit-card text-blue-500 text-xl"></i>
                      </div>
                    </div>
                    <div class="flex justify-content-between">
                      <div>
                        <span class="text-500 mr-2">Заблокированно карт:</span>
                        <span class="text-red-600 font-medium"
                          >{{ $store.state.company.curCompany.card_block }}
                        </span>
                      </div>
                      <router-link
                        data-v-step="1"
                        class="underline text-primary"
                        @click="goToCard"
                        v-ripple
                        to="/card"
                        >Подробнее</router-link
                      >
                    </div>
                  </div>
                </div>
                <div class="col-12 lg:col-6 xl:col-3">
                  <div
                    class="surface-card shadow-2 p-3 h-full flex flex-column justify-content-between border-1 border-50 border-round"
                  >
                    <div class="flex justify-content-between mb-3">
                      <div>
                        <span class="block text-500 font-medium mb-3"
                          >Объем</span
                        >
                        <div class="text-900 font-medium text-xl">
                          {{
                            $store.state.company.curCompany.sum_current
                              ? $store.state.company.curCompany.sum_current +
                                " л - "
                              : "0 л - "
                          }}
                          текущий месяц
                        </div>
                      </div>
                      <div
                        class="flex align-items-center justify-content-center bg-purple-100 border-round"
                        style="width: 2.5rem; height: 2.5rem"
                      >
                        <i class="pi pi-database text-purple-500 text-xl"></i>
                      </div>
                    </div>
                    <span class="text-500">
                      {{
                        $store.state.company.curCompany.sum_last
                          ? $store.state.company.curCompany.sum_last + " л - "
                          : "0 л - "
                      }}
                      прошлый месяц
                    </span>
                  </div>
                </div>
                <!-- <div  class="col-12">
                  <div class="surface-card shadow-2 border-round p-0 h-full">
                    <the-banners />
                  </div>
                </div> -->
                <div class="col-12 xl:col-4 xl:block hidden">
                  <div class="surface-card shadow-2 border-round p-4 h-full">
                    <PieChart />
                  </div>
                </div>
                <div class="col-12 xl:col-8 xl:block hidden">
                  <div class="surface-card shadow-2 border-round p-4">
                    <LineChart />
                  </div>
                </div>
                <div v-if="trnList" class="col-12">
                  <div class="border-round py-4">
                    <div class="text-xl text-900 font-medium mb-5">
                      Транзакции за сегодня
                    </div>
                    <div v-if="trnList.length > 0">
                      <transition-group name="slide-fade">
                        <div
                          v-for="trn in trnList"
                          :key="trn.id_trn"
                          class="mb-5 flex"
                        >
                          <div
                            class="flex flex-column align-items-center"
                            style="width: 2rem"
                          >
                            <span
                              class="bg-green-500 text-0 flex align-items-center justify-content-center border-circle"
                              style="min-width: 2.5rem; min-height: 2.5rem"
                            >
                              <span style="line-height: 0" class="text-xl"
                                >Р</span
                              >
                            </span>
                            <div
                              class="h-full bg-green-500"
                              style="width: 2px; min-height: 4rem"
                            ></div>
                          </div>
                          <div
                            v-if="$store.state.curUser"
                            class="ml-5 surface-card shadow-2 border-round p-3 flex-auto"
                          >
                            <div
                              class="mb-3 flex flex-wrap-reverse align-items-center justify-content-between"
                            >
                              <span
                                class="text-900 font-medium inline-block mr-3"
                                >Карта:
                                <i class="mt-2 sm:mt-0 inline-block">{{
                                  trn.card_number
                                }}</i></span
                              >
                              <span class="text-500 text-sm">{{
                                formaterDate(trn.date)
                              }}</span>
                            </div>
                            <span class="text-900 font-medium inline-block mr-3"
                              >Держатель:
                              <i>{{
                                trn.holder ? trn.holder.name : " Без держателя"
                              }}</i></span
                            >
                            <div
                              class="flex align-items-stretch flex-wrap text-sm"
                            >
                              <div class="mr-5 mt-3">
                                <span class="font-medium text-500">Услуга</span>
                                <div class="text-700 mt-2">
                                  <b> {{ trn.service_name }}</b>
                                </div>
                              </div>
                              <div class="mr-5 mt-3">
                                <span class="font-medium text-500"
                                  >Количество</span
                                >
                                <div class="text-700 mt-2">
                                  <b> {{ trn.amount }}</b>
                                </div>
                              </div>
                              <div v-if="!$store.state.curUser.hide_price">
                                <div class="mr-5 mt-3">
                                  <span class="font-medium text-500"
                                    >Сумма/Сумма ТО</span
                                  >
                                  <div class="text-700 mt-2">
                                    <b> {{ trn.sum + "/" + trn.sum_to }}</b>
                                  </div>
                                </div>
                              </div>
                              <div v-else>
                                <div class="mr-5 mt-3">
                                  <span class="font-medium text-500"
                                    >Сумма</span
                                  >
                                  <div class="text-700 mt-2">
                                    <b> {{ trn.sum }}</b>
                                  </div>
                                </div>
                              </div>
                              <div class="mr-5 mt-3">
                                <span class="font-medium text-500">ТО</span>
                                <div class="text-700 mt-2">
                                  <b> {{ trn.group_to }}</b>
                                </div>
                              </div>
                              <div v-if="!$store.state.curUser.hide_price">
                                <div class="mr-5 mt-3">
                                  <span class="font-medium text-500"
                                    >Цена/Цена ТО</span
                                  >
                                  <div class="text-700 mt-2">
                                    <b> {{ trn.price + "/" + trn.price_to }}</b>
                                  </div>
                                </div>
                              </div>
                              <div v-else>
                                <div class="mr-5 mt-3">
                                  <span class="font-medium text-500">Цена</span>
                                  <div class="text-700 mt-2">
                                    <b> {{ trn.price }}</b>
                                  </div>
                                </div>
                              </div>
                              <div class="mt-3">
                                <span class="font-medium text-500">Адрес</span>
                                <div class="text-700 mt-2">
                                  <b> {{ trn.to_address }}</b>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </transition-group>
                      <div
                        v-if="trnList[0].total_count > 5"
                        class="flex justify-content-center"
                      >
                        <router-link class="p-button p-component" to="/trn">
                          Подробнее</router-link
                        >
                      </div>
                    </div>
                    <div v-else>
                      Транзакций за сегодня нет, за другие дни можно посмотреть
                      в разделе
                      <router-link to="/trn"> "Транзакции"</router-link>
                    </div>
                  </div>
                </div>

                <div class="col-12 xl:col-6">
                  <div class="surface-card shadow-2 border-round p-4">
                    <div class="text-xl text-900 font-medium mb-5">
                      О компании
                    </div>
                    <ul v-if="$store.state.curUser" class="list-none p-0 m-0">
                      <div v-if="!$store.state.curUser.hide_dis">
                        <div
                          v-for="(item, i) in $store.state.company.curCompany
                            .discount"
                          :key="i"
                        >
                          <Message
                            severity="success"
                            icon="pi-percentage"
                            :closable="false"
                          >
                            <div
                              class="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-3 w-full"
                            >
                              <div>
                                <div>
                                  {{
                                    item.groupTO !== null
                                      ? item.groupTO.name
                                      : ""
                                  }}
                                  <span class="font-bold">{{ item.name }}</span>
                                </div>
                                <p class="mt-2 mb-0">
                                  Период действия:
                                  <span class="font-medium">
                                    {{
                                      formaterDate(item.date_start, false) +
                                      " - " +
                                      formaterDate(item.date_end, false)
                                    }}</span
                                  >
                                </p>
                              </div>
                              <div
                                class="flex align-items-center justify-content-between md:justify-content-end mt-3 md:mt-0"
                              >
                                <div class="text-right ml-3">
                                  <div class="">
                                    <Listbox
                                      v-if="item.service.length > 4"
                                      style="
                                        height: 100px;
                                        overflow: auto;
                                        background-color: transparent;
                                      "
                                      v-model="selectedCity"
                                      :options="item.service"
                                      optionLabel="name"
                                    />
                                    <div v-else>
                                      <span
                                        v-for="discount in item.service"
                                        :key="discount"
                                      >
                                        <b> {{ discount.name + " " }}</b>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Message>
                        </div>
                      </div>
                    </ul>
                    <ul class="list-none p-0 m-0">
                      <div v-if="$store.state.curUser">
                        <li
                          v-if="$store.state.curUser.role !== 2"
                          class="pb-3 border-bottom-1 flex justify-content-between surface-border"
                        >
                          <div class="font-medium text-900 mb-2">Поставщик</div>
                          <div>
                            <span
                              class="line-height-3 text-600"
                              style="max-width: 30rem"
                              v-for="(hc, i) in $store.state.company.curCompany
                                .handler_card"
                              :key="i + '3211d'"
                            >
                              "{{ hc.handler__name + "" }}"
                            </span>
                          </div>
                        </li>
                      </div>
                      <li
                        class="py-3 border-bottom-1 flex justify-content-between surface-border"
                      >
                        <div class="font-medium text-900 mb-2">Типы карт</div>
                        <div>
                          <span
                            class="line-height-3 text-600"
                            style="max-width: 30rem"
                            v-for="(ct, i) in $store.state.company.curCompany
                              .card_type"
                            :key="i + '32dfs11d'"
                          >
                            "{{ ct.card_type__name + "" }}"
                          </span>
                        </div>
                      </li>
                      <li
                        class="py-3 border-bottom-1 flex justify-content-between surface-border"
                      >
                        <div class="font-medium text-900 mb-2">
                          Фактический адрес
                        </div>
                        <div
                          class="line-height-3 text-600"
                          style="max-width: 30rem; text-align: right"
                        >
                          {{ $store.state.company.curCompany.adress }}
                        </div>
                      </li>
                      <li
                        class="py-3 border-bottom-1 flex justify-content-between surface-border"
                      >
                        <div class="font-medium text-900 mb-2">ИНН</div>
                        <div
                          class="line-height-3 text-600"
                          style="max-width: 30rem"
                        >
                          {{ $store.state.company.curCompany.inn }}
                        </div>
                      </li>
                      <li
                        class="py-3 border-bottom-1 flex justify-content-between surface-border"
                      >
                        <div class="font-medium text-900 mb-2">КПП</div>
                        <div
                          class="line-height-3 text-600"
                          style="max-width: 30rem"
                        >
                          {{ $store.state.company.curCompany.kpp }}
                        </div>
                      </li>
                    </ul>
                    <!-- <div class="flex justify-content-between pt-3">
                      <Button
                        label="Clear All"
                        class="p-button-outlined p-button-secondary w-6 mr-2"
                      ></Button>
                      <Button
                        label="New Entry"
                        class="p-button-outlined w-6 ml-2"
                      ></Button>
                    </div> -->
                  </div>
                </div>
                <div class="col-12 xl:col-6">
                  <div class="surface-card shadow-2 border-round p-4">
                    <Contacts />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Skeleton width="100%" height="100%" />
  </layout-base>
</template>
<script>
import store from "@/store";
import FilterCompany from "@/components/FilterCompany.vue";
import config from "@/configs";
import { getCompanyHome, getTransactions } from "@/api/company";
import MobileSpiner from "@/components/UI/MobileSpiner";
import { timeFormater } from "@/handlers/timeFormater";
import PieChart from "../components/PieChart.vue";
import LineChart from "../components/LineChart.vue";
import ContactAdd from "../components/ContactAdd.vue";
import Contacts from "../components/Contacts.vue";
import TheCalendarFromTo from "../components/TheCalendarFromTo.vue";
import TheBanners from "../components/TheBanners.vue";
import BalancesCard from "../components/Home/BalancesCard.vue";
import ThePaginator from "../components/UI/ThePaginator.vue";

export default {
  data() {
    return {
      currentPage: 1,
      perPage: 5,
      total: 100,
      config: config,
      loading: true,
      companies: [],
      searchString: "",
      active: 0,
      displayModal: false,
      selectedColumns: null,
      columns: null,
      selectedProduct1: null,
      cura: [],
      trnList: null,
      options: {
        animations: {
          tension: {
            duration: 500,
            easing: "ease",
            from: 0.7,
            to: 1,
            loop: true,
          },
        },
        scales: {
          y: {
            // defining min and max so hiding the dataset does not change scale range
            min: 0,
            max: 120,
          },
        },
      },
      basicData: {
        labels: [
          "Январь",
          "Февраль",
          "Март",
          "Апрель",
          "Май",
          "Июнь",
          "Июль",
          "Август",
          "Сентябрь",
          "Октябрь",
          "Ноябрь",
          "Декабрь",
        ],
        datasets: [
          {
            label: "Итоги в литрах",
            data: [65, 59, 80, 81, 56, 55, 40, 81, 56, 55, 40, 40],
            fill: false,
            borderColor: "#42A5F5",
            tension: 0.4,
          },
          {
            label: "Итоги в рублях",
            data: [28, 48, 40, 19, 86, 27, 90, 59, 80, 81, 56, 81],
            fill: false,
            borderColor: "#FFA726",
            tension: 0.4,
            options: {
              animations: {
                tension: {
                  duration: 1000,
                  easing: "linear",
                  from: 1,
                  to: 0,
                  loop: true,
                },
              },
              scales: {
                y: {
                  // defining min and max so hiding the dataset does not change scale range
                  min: 0,
                  max: 100,
                },
              },
            },
          },
        ],
      },
      chartData: {
        labels: ["AИ-95", "AИ-80", "ДТ", "АИ-92", "АИ-56", "АИ-80"],
        datasets: [
          {
            data: [300, 50, 100, 300, 20, 600],
            backgroundColor: [
              "#42A5F5",
              "#66BB6A",
              "#FFA726",
              "#F15726",
              "#06B26A",
            ],
            hoverBackgroundColor: [
              "#64B5F6",
              "#81C784",
              "#FFB74D",
              "#F15726",
              "#06B26A",
            ],
          },
        ],
      },
      lightOptions: {
        plugins: {
          legend: {
            labels: {
              color: "#495057",
            },
          },
        },
      },
    };
  },
  components: {
    FilterCompany,
    PieChart,
    LineChart,
    ContactAdd,
    Contacts,
    MobileSpiner,
    TheCalendarFromTo,
    TheBanners,
    BalancesCard,
    ThePaginator,
  },
  computed: {
    curCompany() {
      // this.valueCompany = store.state.curCompany
      //   ? store.state.curCompany
      //   : null;
      return store.state.company.curCompany;
    },
    curCompanyId() {
      if (store.state.company.curCompany) {
        return store.state.company.curCompany.id;
      } else {
        return 0;
      }
    },
  },
  watch: {
    curCompany() {
      getTransactions(this);
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
  },
  methods: {
    onPage(data) {
      console.log(data);
    },
    goToCard() {
      sessionStorage.removeItem("card-control-table");
    },
    onRowSelect(event) {
      store.commit("setCurCompany", event);
      // this.cura[0] = event;
      getTransactions(this);
    },
    formaterDate(timeValue, hour) {
      return timeFormater(timeValue, hour);
    },
    getCom() {
      this.valueCompany = curCompany();
    },
    logger(data) {
      console.log(data);
    },
  },
  mounted() {
    // getCompanyHome(this);
    if (store.state.curUser) {
      setTimeout(() => {
        getTransactions(this);
      }, 400);
    }
    setTimeout(() => {
      this.loading = false;
    }, 500);
    window.scrollTo(0, 0);
  },

  created() {
    document.title = "Компании | " + window.appBaseTitle;
    this.columns = [
      { field: "sum_last", header: "Объем за прошлый месяц, л" },
      { field: "sum_current", header: "Объем за текущий месяц, л" },
    ];
    this.selectedColumns = "";
  },
};
</script>

<style lang="scss" >
</style>


 