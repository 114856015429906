<template>
  <div class="flex  justify-content-center">
    <Button
      label="Добавить контакт"
      icon="pi pi-plus"
      iconPos="right"
      class="
        addLimitButton
        py-3
        p-button-text
        flex
        p-button-sm
        justify-content-center
      "
      @click="
        {
          addContact();
        }
      "
    />
  </div>
</template>

<script>
export default {
  name: "ContactsAdd",
  props: {
    display: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      showModal: this.display,
      action: "",
    };
  },
  methods: {
    addContact() {
      this.action = "add";
      this.showModal = !this.showModal;
      this.$emit("showModal", this.showModal, this.action);
    },
    openDialog() {
      this.showModal = !this.showModal;
    },
  },
};
</script>

<style lang="scss">
.p-button-label {
  flex: 0 1 auto;
}
.p-dialog-header {
  justify-content: space-between !important;
}
.p-multiselect {
  border: 1px solid #ced4da !important;
}
.alertLimit {
  background: var(--baseLight);
}
.addLimitButton {
  justify-content: center !important;
}
</style>