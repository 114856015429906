<template>
  <div class="banner border-round shadow-2">
    <div class="banner__wrapper">
      <div class="banner__content">
        <div class="banner__title">Рассрочка для Бизнеса</div>
        <div class="banner__dsc">
          Пополняйте топливный счёт с рассрочкой 30 дней без процентов для ООО и
          ИП
        </div>
        <button class="banner__btn">Узнать условия</button>
      </div>
      <div class="banner__logo">
        <img src="../../public/static/img/banners/logo-sber-biusnes-wh.svg" />
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">

</style>