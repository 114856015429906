<template>
  <div
      class="w-full h-full max-w-full max-h-full none"
      :class="{ relative: this.amountCount.length === 0 }"
  >
    <div class="flex justify-content-between mb-3">
      <div>
        <span class="block text-500 font-medium mb-3"
        >Объем по услугам за текущий месяц</span
        >
        <!-- <router-link class="underline text-primary" v-ripple to="/statistic"
          >Подробнее</router-link
        > -->
      </div>
      <div
          class="
          flex
          align-items-center
          justify-content-center
          bg-blue-100
          border-round
        "
          style="width: 2.5rem; height: 2.5rem"
      >
        <i class="pi pi-database text-blue-500 text-xl"></i>
      </div>
    </div>
    <Chart type="pie" :data="chartData"/>
    <div
        v-if="this.amountCount.length === 0"
        class="
        w-full
        h-full
        top-0
        left-0
        absolute
        flex
        align-items-center
        justify-content-center
      "
    >
      Данные за выбранный период отсутсвуют!
    </div>
  </div>
</template>
<script>
import store from "@/store";
import config from "@/configs";
import {timeFormater} from "@/handlers/timeFormater";
import {getTransactionsChart} from "@/api/trn";

export default {
  data() {
    return {
      dateStartTrn: this.getDate(
          new Date(
              new Date(new Date().getFullYear(), new Date().getMonth(), 1) -
              new Date(
                  new Date().getFullYear(),
                  new Date().getMonth(),
                  1
              ).getTimezoneOffset() *
              60000
          )
              .toISOString()
              .split("T")[0],
          "start"
      ),
      dateEndTrn: this.getDate(new Date().toISOString().split("T")[0], "end"),
      rowsTrn: 1000,
      pageTrn: 1,
      groupTrn: "service",
      transactionsListService: [],
      amountCount: [],
      colors: [],
      chartData: {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: [],
          },
        ],
      },
      lightOptions: {
        plugins: {
          legend: {
            labels: {
              color: "#495057",
            },
          },
        },
      },
    };
  },
  methods: {
    formaterDate(timeValue, hour) {
      return timeFormater(timeValue, hour);
    },
    getDate(date, type) {
      let start = "T00:00:00";
      let end = "T23:59:59";
      return type === "start" ? date + start : date + end;
    },
    setLabelsCharts() {
      this.transactionsListService.forEach((element) => {
        this.chartData.labels.push(element.service__name);
        this.chartData.datasets[0].data.push(element.amount);
      });
    },
    setChart() {
      getTransactionsChart(this);
      this.transactionsListService.length > 0 ? this.setLabelsCharts() : "";
    },
  },
  watch: {
    curCompany() {
      getTransactionsChart(this);
    },
    curUser(value) {

      if (value !== undefined) {
        getTransactionsChart(this)
      }
    }
  },
  computed: {
    curUser() {
      return store.state.curUser;
    },
    curCompany() {
      if (store.state.company.curCompany !== null) {
        return store.state.company.curCompany;
      }
    },
    curCompanyId() {
      if (store.state.company.curCompany !== null) {
        return store.state.company.curCompany.id;
      } else {
        return 0;
      }
    },
  },
  mounted() {

    store.state.curUser !== undefined ? getTransactionsChart(this) : null;
  },
  // created() {
  //   alert('запрос2')
  //   // getTransactionsChart(this);
  //   store.state.curUser !== undefined ? getTransactionsChart(this) : null;
  //
  // },
};
</script>
<style
    lang="scss">
.buttons {
  .p-button {
    /* border: 0 !important;
    text-decoration: underline; */
    flex: 1 1 auto;
  }
}
</style>