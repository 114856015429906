import api from "@/api/index";
import store from "@/store";
import {
    setEmoji
} from "@/handlers/emoji";

export function getProfileSber(that) {
    api
        .post("sber/productInfo", {
            clientId: 1134,
            redirectUrl: `${window.location.origin}`
            // redirectUrl: `https://localhost`

        })
        .then((r) => {
            console.info(
                `${r.statusText} ${r.status} ${setEmoji("access")}`
            );
            console.log(r);
        })
        .catch((err) => {
            console.log(err);
            console.log(err.request.response);
            let url = JSON.parse(err.request.responseText)
            window.open(url, "_self")
            let statusCode = err.request.status;
            let statusText = err.request.statusText;
            console.error(setEmoji("error") + statusCode + " " + statusText);
            switch (statusCode) {
                case 0:
                    that.$toast.add({
                        severity: "warn",
                        summary: `${setEmoji("error")} Нет связи с сервером`,
                        detail: "Попробуйте позднее!",
                    });
                    break;
                case 403:
                    that.$toast.add({
                        severity: "warn",
                        summary: `${setEmoji("lock")} Время сессии истекло`,
                        detail: "Требуется авторизация",
                        life: 5000,
                    });
                    that.$router.push("/login");
                    break;
                case 404:
                    that.$toast.add({
                        severity: "error",
                        summary: `${setEmoji("error")} Ошибка ${statusCode}`,
                        detail: "Запрос не найден",
                        life: 5000,
                    });
                    break;
                default:
                    that.$toast.add({
                        severity: "error",
                        summary: `${setEmoji(
                    "error"
                  )} Ошибка запроса  ${statusCode}`,
                        detail: "Попробуйте позднее!",
                        life: 5000,
                    });
                    break;
            }
        }).finally(() => {
            that.loading = false;
        });
}