import dayjs from "dayjs";
const utc = require("dayjs/plugin/utc");
const timezone = require("dayjs/plugin/timezone");
const customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);
export function days(val, type) {
  console.log(
    dayjs
      .utc(val)
      .local()
      .format()
      .slice(0, dayjs.utc(val).format().length - 1)
  );
  console.log(val);
  // console.log(dayjs("2023-01-12T00:00:00").format());
  // console.log(dayjs("2023-01-12T00:00:00").$d);
  // console.log(val);
  switch (type) {
    case "server":
      return dayjs
        .utc(val, "DD.MM.YYYY")
        .local()
        .format()
        .slice(0, dayjs.utc(val).format().length - 1);
    case "server-start":
      return (
        dayjs
          .utc(val, "DD.MM.YYYY")
          .local()
          .format()
          .slice(0, dayjs.utc(val).local().format().length - 1)
          .split("T")[0] + "T00:00:00"
      );
    case "server-end":
      return (
        dayjs
          .utc(val, "DD.MM.YYYY")
          .local()
          .format()
          .slice(0, dayjs.utc(val).local().format().length - 1)
          .split("T")[0] + "T23:59:59"
      );
    case "normal":
      return dayjs(val).format();
    case "date-hours":
      return dayjs(val).format("DD-MM-YYYY");
    default:
      return dayjs.utc(val);
  }
}

export function daysForCalendar(val, type) {
  switch (type) {
    case "server":
      return dayjs
        .utc(val)
        .local()
        .format()
        .slice(0, dayjs.utc(val).format().length - 1);
    case "server-start":
      return (
        dayjs
          .utc(val)
          .local()
          .format()
          .slice(0, dayjs.utc(val).local().format().length - 1)
          .split("T")[0] + "T00:00:00"
      );
    case "server-end":
      return (
        dayjs
          .utc(val)
          .local()
          .format()
          .slice(0, dayjs.utc(val).local().format().length - 1)
          .split("T")[0] + "T23:59:59"
      );
    case "normal":
      return dayjs(val).format();
    default:
      return dayjs.utc(val);
  }
}

export function getShortcutDate(val) {
  let date = { start: "", end: "" };
  switch (val) {
    case "today":
      date.start = dayjs().$d;
      date.end = dayjs().$d;
      return date;
    case "yestoday":
      date.start = dayjs().set("date", dayjs().$D - 1).$d;
      date.end = dayjs().set("date", dayjs().$D - 1).$d;
      return date;
    case "curMounth":
      date.start = dayjs(dayjs().startOf("month")).$d;
      date.end = dayjs().$d;
      return date;
    case "lastMounth":
      date.start = dayjs()
        .set("month", dayjs().$M - 1)
        .startOf("month").$d;
      date.end = dayjs()
        .set("month", dayjs().$M - 1)
        .endOf("month").$d;
      return date;
  }
}
