<template>
  <div
    class="surface-card shadow-2 pt-3 px-0 pb-0 border-0 border-50 border-round h-full flex flex-column justify-content-between"
  >
    <div class="px-3">
      <div class="flex justify-content-between mb-3">
        <div>
          <span class="block text-500 font-medium mb-3">Баланс</span>
          <div class="text-900 font-medium text-xl">
            <div
              class=""
              :class="{
                'text-red-600':
                  $store.state.company.curCompany.ballance < 0 ||
                  $store.state.company.curCompany.status == 2,
                'text-teal-600': $store.state.company.curCompany.ballance > 0,
              }"
            >
              <i
                class="pi mr-2"
                :class="{
                  'pi-arrow-down': $store.state.company.curCompany.ballance < 0,
                  'pi-arrow-up': $store.state.company.curCompany.ballance > 0,
                }"
              ></i>
              <b> {{ $store.state.company.curCompany.ballance }}</b>
            </div>
          </div>
        </div>
        <div
          class="flex align-items-center justify-content-center bg-green-100 border-round"
          style="width: 2.5rem; height: 2.5rem"
        >
          <i class="pi pi-money-bill text-green-500 text-xl"></i>
        </div>
      </div>
      <!-- style="cursor: no-drop; width: fit-content" -->
      <div class="flex justify-content-between align-items-end">
        <!-- <Button
        class="p-button-sm"
        @click="display = !display"
        label="Пополнить баланс"
      /> -->
        <create-bill-sber
          v-if="isTestSever && showSber"
          :isDisabled="$store.state.company.curCompany.bill"
          :companyName="$store.state.company.curCompany.name"
          :idCompany="$store.state.company.curCompany.id"
        />
        <create-bill
          v-else
          :isDisabled="$store.state.company.curCompany.bill"
          :companyName="$store.state.company.curCompany.name"
          :idCompany="$store.state.company.curCompany.id"
        />

        <router-link class="underline text-primary" v-ripple to="/payments"
          >Подробнее</router-link
        >
      </div>
    </div>
    <div
      class="text-center py-1 mt-3 font-bold text-white text-sm border-round border-noround-top"
      :class="statusClass"
    >
      {{ statusName }}
    </div>
  </div>

  <!-- <Dialog
    class="p-0"
    header="Пополнить баланс"
    style="min-width: 400px"
    v-model:visible="display"
  >
    <div class="my-4" key="">
      <h4 class="font-bold" for="blns">Сумма для пополнения, RUB</h4>
      <InputText inputId="blns" class="w-full mt-2" />
    </div>
    <div class="my-4">
      <h4 class="font-bold" for="blns">Способ пополнения</h4>
      <div class="w-full mt-2">
        <div
          class="
            border-1
            p-3
            border-round-top
            flex
            align-items-center
            cursor-pointer
          "
          :class="{
            'surface-border': tier1 !== 0,
            'border-primary': tier1 === 0,
          }"
          @click="tier1 = 0"
        >
          <RadioButton
            name="tier1"
            :value="0"
            v-model="tier1"
            class="mr-3"
          ></RadioButton>
          <div class="mr-4 md:mr-8">
            <div class="font-medium mb-1">Перевод по реквизитам</div>
            <span class="text-sm">Отгрузка по факту поступления средств</span>
          </div>
        </div>
        <div
          class="border-1 p-3 flex align-items-center cursor-pointer"
          :class="{
            'surface-border': tier1 !== 1,
            'border-primary': tier1 === 1,
          }"
          @click="tier1 = 1"
        >
          <RadioButton
            name="tier1"
            :value="1"
            v-model="tier1"
            class="mr-3"
          ></RadioButton>
          <div class="mr-4 md:mr-8">
            <div class="font-medium mb-1">
              В рассрочку, 30 дней без процентов
            </div>
            <span class="text-sm"> 
            1,5% в месяц с 31-го дня, до 3 млн <br>От ПАО Сбербанк </span>
          </div>
        </div>
      </div>
    </div>
    <div  
      class="flex align-items-center"
      :class="{
        'justify-content-end': tier1 !== 1,
        'justify-content-between': tier1 === 1,
      }"
    >
    К сожалению, вы не соответствуете условиям данной программы. Ознакомьтесь с&nbsp;<a href="#">условиями кредитования</a>.
      <a v-if="tier1 === 1" href="#"
        >Подробнее об условиях <i class="pi pi-external-link text-sm ml-2"></i
      ></a>
      <div>
        <Button v-if="tier1 === 0" label="Пополнить" />
        <div style="max-width: 151px" class="flex flex-column" v-else>
          <Button disabled class="p-button-rounded sber-btn">
            <img src="../../../public/static/img/sber-logo.svg" />
            Пополнить</Button
          >
          <span style="margin-top: 4px" class="block text-center text-sm"
            >Для оформления вы перейдете в СберБизнес</span
          >
        </div>
      </div>
    </div>
    <div v-if="tier1 === 1" class="flex justify-content-center mt-8">
      <Button class="sber-btn p-button-rounded">
        <img src="../../../public/static/img/sber-logo.svg" />Войти по
        СберБизнес ID</Button
      >
    </div>
  </Dialog> -->
</template>

<script>
import CreateBill from "./CreateBill.vue";
import CreateBillSber from "./CreateBillSber.vue";
export default {
  components: { CreateBill, CreateBillSber },
  name: "BalancesCard",
  data() {
    return {
      display: false,
      tier1: 0,
    };
  },
  props: {
    statusName: {
      type: String,
      requaried: true,
    },
    statusClass: {
      type: String,
      requaried: true,
    },
  },
  computed: {
    isTestSever() {
      let href = document.location.host;

      switch (href) {
        case "tanos.dt-teh.ru":
          return true;
        case "localhost:8080":
          return true;
        default:
          return false;
      }
    },
    showSber() {
      return this.$store.state.curUser.show_sber;
    },
  },
  mounted() {},
};
</script>

<style lang="scss">
.add-money {
  &:disabled {
    cursor: no-drop !important;
  }
}
.sber-btn {
  background-color: #107f8c !important;
  padding: 12px 24px;
  display: flex;
  justify-content: center;
  height: 40px;
  border: 0 !important;
  transition: all 0.2s ease;
  &::hover {
    border: 0 !important;
    background-color: #21a19a !important;
    background: #21a19a !important;
  }
  &:disabled {
    background-color: #90d0cc !important;
    background: #90d0cc !important;
  }
  img {
    width: 22px;
    margin-right: 8px;
  }
}
</style>