<template>
  <div class="flex justify-content-between align-items-center mb-5">
    <span class="text-xl text-900 font-medium">Контакты</span>
  </div>

  <ul
    v-if="$store.state.company.curCompany.contact?.length !== 0"
    class="list-none p-0 m-0"
  >
    <li
      style="border-bottom: 1px solid #eaeaea"
      class="
        flex flex-column
        md:flex-row md:align-items-center md:justify-content-between
        mb-4 
        py-3 px-2 hover:bg-bluegray-50
      "
      v-for="(contact, index) in $store.state.company.curCompany.contact"
      :key="contact.pk"
    >
      <ContactsItem
        v-on:delContact="delContact"
        v-on:editContact="editContact"
        :contact="contact"
        :index="index"
        :display="display"
      />
    </li>
  </ul>
  <div v-else>Контактные данные сотрудников отсутствуют</div>
  <ContactAdd :display="display" v-on:showModal="showModal" />
  <Dialog
    v-model:visible="display"
    class="shadow-6"
    :style="{ width: '80vw' }"
    :modal="true"
  >
    <template #header> <b class="text-xl mb-3">Добавить контакт</b> </template>
    <div class="px-4 pt-4 md:px-2 lg:px-2">
      <div class="grid">
        <div class="col-12 lg:col-2">
          <div class="text-900 font-medium text-xl mb-3">Данные cотрудника</div>
          <p class="m-0 p-0 text-600 line-height-3 mr-3">
            Введите данные сотрудника:
          </p>
        </div>
        <div class="col-12 lg:col-10">
          <div class="grid formgrid p-fluid">
            <div class="field mb-4 col-12 md:col-6">
              <label for="nickname" class="font-medium text-900"
                >Фамилия Имя Отчество</label
              >
              <InputText id="nickname" v-model="dataUser.fio" type="text" />
            </div>
            <div class="field mb-4 col-12 md:col-6">
              <label for="city" class="font-medium text-900">Должность</label>
              <InputText type="text" v-model="dataUser.position" />
            </div>
            <div class="field mb-4 col-12 md:col-6">
              <label for="email" class="font-medium text-900">Телефон</label>
              <InputMask
                mask="79999999999"
                v-model="dataUser.phone"
                type="text"
              />
            </div>
            <div class="field mb-4 col-12 md:col-6">
              <label for="state" class="font-medium text-900">E-mail</label>
              <InputText id="email" type="text" v-model="dataUser.email" />
            </div>

            <div class="col-12">
              <Button
                @click="postContact"
                label="Сохранить"
                class="w-auto mt-3"
              ></Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Dialog>
</template>

<script>
import store from "@/store";
import ContactAdd from "../components/ContactAdd.vue";
import ContactsItem from "./ContactsItem.vue";
import { postContacts } from "../api/contacts";
import { validatorEmails } from "@/handlers/validatorEmails";

export default {
  data() {
    return {
      dataUser: {
        pk: "",
        action: "",
        fio: "",
        phone: "",
        email: "",
        position: "",
      },
      display: false,
      indexContact: "",
    };
  },
  components: {
    ContactAdd,
    ContactsItem,
  },
  computed: {
    curCompany() {
      return store.state.company.curCompany;
    },
  },
  watch: {
    curCompany() {},
  },
  methods: {
    showModal(parametr, action) {
      (this.dataUser = {
        pk: "",
        action: "",
        fio: "",
        phone: "",
        email: "",
        position: "",
      }),
        (this.display = parametr);
      this.dataUser.action = action;
    },
    delContact(pk, action, index) {
      this.dataUser = {
        pk: "",
        action: "",
        fio: "",
        phone: "",
        email: "",
        position: "",
      };
      this.dataUser.action = action;
      this.dataUser.pk = pk;
      this.indexContact = index;
      postContacts(this);
    },
    editContact(dataUser, parametr, index) {
      this.indexContact = index;
      this.dataUser = {
        pk: dataUser.pk,
        action: dataUser.action,
        fio: dataUser.fio,
        phone: dataUser.phone,
        email: dataUser.email,
        position: dataUser.position,
      };
      this.display = parametr;
    },
    postContact() {
      if (this.dataUser.action === "add") {
        if (this.dataUser.fio?.length !== 0) {
          if (this.dataUser.email?.length > 0) {
            if (validatorEmails(this.dataUser.email)) {
              postContacts(this);
              this.display = false;
            } else {
              this.$toast.add({
                severity: "warn",
                summary: `Пожалуйста проверьте правильность заполнения поля Email`,
                detail: `Пример: example@empty.com`,
                life: 5000,
              });
            }
          } else {
            postContacts(this);
            this.display = false;
          }
        } else {
          this.$toast.add({
            severity: "warn",
            summary: `Пожалуйста заполните обязательное поле`,
            detail: `Фамилия Имя Отчество`,
            life: 5000,
          });
        }
      } else if (this.dataUser.action === "edit") {
        if (this.dataUser.fio?.length !== 0) {
          if (this.dataUser.email?.length > 0) {
            if (validatorEmails(this.dataUser?.email)) {
              postContacts(this);
              this.display = false;
            } else {
              this.$toast.add({
                severity: "warn",
                summary: `Пожалуйста проверьте правильность заполнения поля email`,
                detail: `Пример: example@empty.com`,
                life: 5000,
              });
            }
          } else {
            postContacts(this);
            this.display = false;
          }
        } else {
          this.$toast.add({
            severity: "warn",
            summary: `Пожалуйста заполните обязательное поле`,
            detail: `Фамилия Имя Отчество`,
            life: 5000,
          });
        }
      }
    },
  },
  mounted() {},

  created() {},
};
</script>
<style lang="scss">
</style> 