<template>
  <div class="flex">
    <div>
      <span class="block text-900 font-medium mb-1">{{ contact.fio }}</span>
      <div class="text-600">{{ contact.position }}</div>
    </div>
  </div>
  <div class="flex">
    <div class="mt-2 mr-4 md:mt-0 flex flex-nowrap flex-column align-items-end">
      <span class="mb-3" v-if="contact.email.length > 0">
        <a target="_blank" :href="'mailto:' + contact.email">
          <i class="pi pi-envelope mr-2"></i>
          {{ contact.email }}</a
        >
      </span>
      <span class="mb-3" v-else> Почта не указана </span>
      <span v-if="contact.phone.length > 0">
        <a target="_blank" :href="`tel:+${contact.phone}`">
          <i class="pi pi-phone mr-2"></i>
          {{ contact.phone }}</a
        >
      </span>
      <span class="mb-3" v-else> Номер не указан </span>
    </div>
    <Button
      @click="editContact()"
      v-tooltip.left="'Редактировать контакт'"
      class="p-button-sm p-button-primary p-button-icon-only p-button-text"
      icon="pi pi-pencil"
    />
    <Button
      @click="delContact()"
      v-tooltip.left="'Удалить контакт'"
      class="p-0 p-button-sm p-button-danger p-button-icon-only p-button-text"
      icon="pi pi-trash"
    ></Button>
  </div>
 </template>
  
  <script>
import store from "@/store";
export default {
  name: "ContactsItem",
  props: {
    contact: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    display: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      pk: this.contact.pk,
      action: "",
      dataUser: {
        pk: "",
        action: "",
        fio: "",
        phone: "",
        email: "",
        position: "",
      },
      showModal: this.display,
    };
  },
  components: {},
  computed: {
    curCompany() {
      return store.state.company.curCompany;
    },
  },
  watch: {
    curCompany() {},
  },
  methods: {
    delContact() {
      2;
      this.action = "del";
      this.$emit("delContact", this.pk, this.action, this.index);
    },
    editContact() {
      this.dataUser.pk = this.contact.pk;
      this.dataUser.action = "edit";
      this.dataUser.fio = this.contact.fio;
      this.dataUser.phone = this.contact.phone;
      this.dataUser.email = this.contact.email;
      this.dataUser.position = this.contact.position;
      this.showModal = !this.showModal;
      this.$emit("editContact", this.dataUser, this.showModal, this.index);
    },
  },
  mounted() {},

  created() {},
};
</script>
  <style lang="scss">
</style> 